import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert'


function CourseSyllabus () {
  const history = useHistory();
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: ''
  })

  const { first_name, last_name, email, phone } = values

  const handleChange = type => e => {
    setValues({ ...values, [type]: e.target.value })
  }

  const UserData = {
    first_name,
    last_name,
    email,
    phone,
    text: 'Submit'
  }

  const onFormSubmit = event => {
    event.preventDefault()

    const data = UserData;
    const url = 'https://registrationapi.ivyarc.com/v1/media/download-syllabus';
    const method = 'POST';
    axios({
      data,
      url,
      method,
      responseType: 'application/json',
    })
    .then((response) => {
      swal({
        text: `Course Syllabus successfully sent to ${UserData.email}`,
        icon: 'success',
        title: 'Email sent'
      }).then(() => history.push('/'))
    })
    .catch(err => {
      if (err.response && err.response.data) {
        swal({
          text: err.response.data.message,
          icon: 'error',
          title: 'Failed'
        })
            .then(() => {
              history.push('/CourseSyllabus')
            })
      } else {
        swal({
          text: 'An error occurred. Please try again!',
          icon: 'error',
          title: 'Failed'
        })
        .then(() => {
          history.push('/CourseSyllabus')
        })
      }
    })
  };

  return (
    <div
      className='container mt-5 bg-light text-dark'
      style={{
        boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.2)',
        marginTop: '30px'
      }}
    >
      <div id='courseForm' style={{ padding: '25px' }}>
        <div className='form-header pb-3'>
          <h3
            className='form
            -title text-dark text-left form--Title-Name pb-3'
            id='courseFormTitle'
          >
            <Link to='/'>
              <div>
                <span
                  className='iconify RegForm--icon'
                  data-icon='ic:baseline-arrow-back'
                  data-inline='false'
                />
              </div>
            </Link>
            <div className='RegForm--title'>Syllabus Form</div><br/>
            
          </h3>
          <div> <span style={{color: "orange"}}>NOTE: Syllabus will be sent to registered email</span></div>
          <br />
          <form method={'POST'}>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='form-group'>
                  <label htmlFor='firstName'>First Name</label>
                  <input
                    type='firstName'
                    className='form-control'
                    value={first_name}
                    onChange={handleChange('first_name')}
                    placeholder='Enter First Name'
                    required
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='lastName'>Last Name</label>
                  <input
                    type='lastName'
                    className='form-control'
                    value={last_name}
                    onChange={handleChange('last_name')}
                    placeholder='Enter Last Name'
                    required
                  />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='form-group'>
                  <label htmlFor='email'>Email</label>
                  <input
                    type='email'
                    className='form-control'
                    value={email}
                    onChange={handleChange('email')}
                    placeholder='Enter Email'
                    required
                  />
                  <small id='emailHelp' className='form-text text-muted'>
                    We'll never share your email with anyone else.
                  </small>
                </div>
                <div className='form-group'>
                  <label htmlFor='phone'>Phone</label>
                  <input
                    type='phone'
                    className='form-control'
                    value={phone}
                    onChange={handleChange('phone')}
                    placeholder='Enter Phone'
                    required
                  />
                  <small id='phoneHelp' className='form-text text-muted'>
                    Please add your country code
                  </small>
                </div>
              </div>
              <button
                type='submit'
                className='submit2-disable'
                UserData={UserData}
                onClick={onFormSubmit}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default CourseSyllabus
