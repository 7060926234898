import React from 'react'
import Faq from 'react-faq-component'
import { Link } from 'react-router-dom'


function FAQ () {
  const data = {
    title: 'FAQ (Frequently Asked Questions)',
    rows: [
      {
        title: 'How Long Will it Take?',
        content: 'Just a total of 12 weeks to transform you into a Full Stack Developer.'
      },
      {
        title: 'What Will I Get From The Course?',
        content:
          'Full Stack Development Skills, a Strong Leadership Mindset, and an IVYARC Certificate proving that you made it through better than ever.'
      },
      {
        title: 'What Will I Need For The Course?',
        content:
          'A working computer with a clear microphone and a stable internet connection'
      },
      {
        title: 'Is The Course Completely Online?',
        content: 'Yes, we will not ever require you to physically be anywhere to enjoy what this course has to offer. '
      },
      {
        title: 'How Can I Apply?',
        content: 'Simply click the ‘Apply Now’ button, tell us a bit about yourself, pay your registration fee, and you’re good to go. '
      },
      {
        title: 'Can I Make Instalment Payments?',
        content: 'We are flexible with our payment plans, you can freely make use of our Instalment Payment Plan - Pay a half of the programme fee at the start of the course and the rest by week 6 of the programme '
      }
    ]
  }

  return (
    <div
      className='container-fluid mt-5 bg-white text-dark'
      style={{
        boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.2)'
      }}
    >
      <div id='faq'>
        <div className='form-header'></div>
      </div>
      <div className='faq--con'>
        <h3
          className='form-title text-dark text-left form--Title-Name'
          id='faqTitle'
        >
          <Link to='/'>
            <div>
              <span
                className='iconify RegForm--icon'
                data-icon='ic:baseline-arrow-back'
                data-inline='false'
              />
            </div>
          </Link>
        </h3>
        <Faq data={data} className='faq--text' />
      </div>
    </div>
  )
}


export default FAQ


