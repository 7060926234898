import React, { useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import axios from "axios"
import * as yup from "yup"
import { Formik } from "formik"
import Swal from "sweetalert2"
import Logo from "../images/logopic.png"

function Modal() {
  const history = useHistory();
  let checkedValues = []
  const handleCheckbox = value => {
    if (checkedValues.includes(value)) {
      checkedValues = checkedValues.filter(e => e !== value)
    } else {
      checkedValues.push(value)
    }
  }

  const schema = yup.object({
    name: yup
      .string()
      .required("This is a required field")
      .min(3, "Must be atleast 3 characters."),
    email: yup
      .string()
      .required("This is a required field.")
      .matches(
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        "Email format is incorrect."
      ),
    phone: yup
      .string()
      .required("This is a required field.")
      .min(10, "Must be atleast 10 characters."),
  })

  return (
    <div className="Container">
      <div className="Content">
        <div className="Logo">
          <img src={Logo} alt="" />
        </div>
        <div className="Modal-Background">
          <div className="Modal-Container" onClick={e => e.stopPropagation()}>
            <button className="close-button">
              X
            </button>
            <div className="Modal-Content">
              <div className="Modal-Title">
                <h2>Equip Yourself for a World of Opportunities in Software Engineering</h2>
              </div>
              <div className="Modal-Body">
                <h5>
                Register a slot today and be first in-line for when our new software development cohort begins by September, 2022.
                </h5>
                <Formik
              initialValues={{
                name: "",
                email: "",
                phone: "",
              }}
              validationSchema={schema}
              onSubmit={values => {
                axios
                  .post("https://registrationapi.ivyarc.com/v1/users/open-day", {
                    name: values.name,
                    email: values.email,
                    phone: values.phone,
                    topics: checkedValues,
                  })
                  .then(res => {
                    checkedValues = []
                    return res
                  })
                  .then(res => {
                    if (res.status === 201) {
                      Swal.fire({
                        icon: "success",
                        title: "Registration Successful",
                        text: res.data.message,
                      });

                      history.push("/");
                    }
                  }).catch(err => {
                    if (err.response) {
                      const {status, data} = err.response
                      if (status === 409) {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: data.message,
                        })
                      }
                    } else {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: 'An error occurred. Please try again',
                      })
                    }
                  })
              }}
            >
              {props => (
                <div>
                  <div className="form-name">
                    <label htmlFor="name">Name *</label>
                    <input
                      type="text"
                      id="name"
                      value={props.values.name}
                      placeholder="Full Name"
                      autoComplete="off"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    {props.touched.name && props.errors.name ? (
                      <p className="error-text">{props.errors.name}</p>
                    ) : null}
                  </div>

                  <div className="form-email">
                    <label htmlFor="email">Email *</label>
                    <input
                      type="text"
                      id="email"
                      value={props.values.email}
                      placeholder="Email Address"
                      autoComplete="off"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    {props.touched.email && props.errors.email ? (
                      <p className="error-text">{props.errors.email}</p>
                    ) : null}
                  </div>

                  <div className="form-phone">
                    <label htmlFor="phone">Phone Number *</label>
                    <input
                      type="number"
                      id="phone"
                      value={props.values.phone}
                      placeholder="Phone Number"
                      autoComplete="off"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    {props.touched.phone && props.errors.phone ? (
                      <p className="error-text">{props.errors.phone}</p>
                    ) : null}
                  </div>

                  <div className="form-topics-container">
                    <span > <h4>Available courses</h4></span>
                    <h6>Kindly indicate your preferred courses of interest</h6>
                    <div className="form-topics">

                    <div className="topic-item">
                     
                     <input
                     
                       type="checkbox"
                       name="management"
                       id="management"
                       onClick={() => handleCheckbox("Agile Methodology")}
                     />
                    
                     <label htmlFor="management">Agile Methodology</label>
                   </div>
                 </div>
               </div>
                      <div className="topic-item">
                        <input
                          type="checkbox"
                          name="coding"
                          id="coding"
                          onClick={() => handleCheckbox("Software Engineering Leadership Programme (Advance Course)")}
                        />
                        <label htmlFor="coding">Software Engineering Leadership Programme - SELP (Advance Course)</label>
                      </div>
                      <div className="topic-item">
                        
                        <input
                          type="checkbox"
                          name="marketing"
                          id="marketing"
                          onClick={() => handleCheckbox("Software Engineering Foundamentals (Beginners course)")}
                        />
                        <label htmlFor="marketing">Software Engineering Foundamentals - SEF (Beginners course)</label>
                      </div>
                      {/* <div className="topic-item">
                        <input
                          type="checkbox"
                          name="design"
                          id="design"
                          onClick={() => handleCheckbox("UI/UX Design")}
                        />
                        <label htmlFor="design">UI/UX Design</label>
                      </div>
                      <div className="topic-item">
                        <input
                          type="checkbox"
                          name="data"
                          id="data"
                          onClick={() => handleCheckbox("CI/CD")}
                        />
                        <label htmlFor="data">CI/CD</label>
                      </div> */}
                

                  <div className="Modal-Footer">
                    <button
                      type="submit"
                      onClick={props.handleSubmit}
                      disabled={props.dirty && props.isValid ? false : true}
                    >
                      Register
                    </button>
                    <span>
                      By sharing your email, you agree to our{" "}
                      <Link to="">Privacy Policy</Link> and{" "}
                      <Link to="">Terms of Service</Link>
                    </span>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default Modal