import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import './register.css';
import axios from 'axios';
import swal from 'sweetalert';
import PaymentSys from './PaymentSys';
import Select from 'react-select';

function RegistrationForm() {
	const publicKey = 'pk_live_4d62a0435d42ca2f21db6963985e858ab7facebd';
	const amount = 4500000;

	let docFile = false;
	const [DocFile, setDocFile] = useState(false);
	const [PicFile, setPicFile] = useState(false);
	let picFile = false;
	const [profile_image, setProfile_image] = useState(null);
	const [cv, setCV] = useState(null);
	const [docUploadError, setDocUploadError] = useState(
		'Please upload file with the right format (pdf, doc)'
	);
	const [docUploadMsg, setDocUploadMsg] = useState('CV uploaded successfully');
	const [imgUploadMsg, setImgUploadMsg] = useState(
		'Image uploaded successfully'
	);
	const [imgUploadError, setImgUploadError] = useState(
		'Please upload file with the right format (png, jpeg, jpg)'
	);
	const [emailError, setEmailError] = useState(
		'User with the provided email is already registered for the internship'
	);
	const [showDocUploadError, setShowDocUploadError] = useState(false);
	const [showImgUploadError, setShowImgUploadError] = useState(false);
	const [showDocUploadMsg, setShowDocUploadMsg] = useState(false);
	const [showImgUploadMsg, setShowImgUploadMsg] = useState(false);
	const [showEmailError, setShowEmailError] = useState(false);
	const [selectedValue, setSelectedValue] = useState([]);
	const [disableCheckBox, setDisableCheckBox] = useState(true);

	const [acceptTerms, setAcceptTerms] = useState(false);
	const ContactIvyarc = 'info@ivyarc.com';
	const history = useHistory();

	const [values, setValues] = useState({
		first_name: '',
		last_name: '',
		email: '',
		age: '',
		password: '',
		gender: '',
		course: '',
		programming_experience: '',
		tech_stacks: [],
		github_account: '',
		transaction_id: '',
		level_of_commitment: '',
		how_did_you_hear_about_us: '',
		payment_scheme: '',
		cv: '',
		profile_image: '',
		expectations_after_training: '',
		accept_terms: acceptTerms,
	});

	const {
		first_name,
		last_name,
		email,
		password,
		phone_number,
		gender,
		course,
		age,
		programming_experience,
		tech_stacks,
		github_account,
		transaction_id,
		level_of_commitment,
		how_did_you_hear_about_us,
		expectations_after_training,
		payment_scheme,
		accept_terms,
	} = values;

	const handleChange = (type) => (e) => {
		setValues({...values, [type]: e.target.value});
	};

	const UserData = {
		first_name,
		last_name,
		email,
		password,
		phone_number,
		gender,
		course,
		age,
		programming_experience,
		tech_stacks,
		github_account,
		transaction_id,
		level_of_commitment,
		how_did_you_hear_about_us,
		payment_scheme,
		cv,
		expectations_after_training,
		amount,
		publicKey,
		accept_terms,
		profile_image,
		text: 'Submit',
	};

	function runProcess() {
		let formFilled = true;
		const formFields = Object.entries(values);

		for (const formFieldsKey of formFields) {
			if (
				formFieldsKey[0] !== 'github_account' &&
				formFieldsKey[0] !== 'accept_terms' &&
				formFieldsKey[0] !== 'transaction_id' &&
				formFieldsKey[0] !== 'cv' &&
				formFieldsKey[0] !== 'profile_image' &&
				!formFieldsKey[1]
			) {
				formFilled = false;
				setDisableCheckBox(true);
			}
			//  else if ((
			//     formFieldsKey[0] === 'cv' && (!docFile && !DocFile))
			//     || (formFieldsKey[0] === 'profile_image' && (!picFile && !PicFile))) {
			//     formFilled = false;
			//     setDisableCheckBox(true)
			// }
		}
		if (formFilled) {
			setDisableCheckBox(false);
		}
	}
	const enableCheckBox = () => (e) => {
		if (e.target.value && e.target.type === 'email') {
			checkForExistingEmail(e);
		} else if (e.target.value) {
			runProcess();
		} else {
			setDisableCheckBox(true);
			setAcceptTerms(false);
		}
	};

	const onFileSubmit = () => async (e) => {
		try {
			const file = e.target.files[0];
			const hasType = imageType.includes(file.type);
			if (!hasType) {
				setPicFile(false);
				picFile = false;
				setShowImgUploadMsg(false);
				const div = document.getElementsByClassName('errorImageMsg');
				if (div.firstChild) {
					return null;
				}
				setImgUploadError('Please provide a valid file (.png/.jpeg/.jpg).');
				setShowImgUploadError(true);
				return null;
			}

			const fromData = new FormData();
			fromData.append('picture', file);
			const config = {
				headers: {
					'content-type': 'multipart/form-data',
				},
			};
			const url = 'https://registrationapi.ivyarc.com/v1/media/upload/';
			const res = await axios.post(url, fromData, config);
			const pic = res.data.data.picture;
			setShowImgUploadMsg(true);
			setProfile_image(pic);
			setShowImgUploadError(false);
			setValues((values) => {
				return {...values, profile_image: pic};
			});
			setPicFile(true);
			picFile = true;
			runProcess();
			// eslint-disable-next-line react-hooks/rules-of-hooks
			//
		} catch (e) {
			setPicFile(false);
			picFile = false;
			setShowImgUploadMsg(false);
			setImgUploadError('An error occurred. Please try again');
			setShowImgUploadError(true);
		}
	};

	const checkForExistingEmail = (e) => {
		if (e.target.value) {
			const url = `https://registrationapi.ivyarc.com/v1/users/check-email/${e.target.value}`;
			axios
				.get(url)
				.then((res) => {
					if (res.data.status === 'Successful') {
						setShowEmailError(false);
						runProcess();
					}
				})
				.catch((error) => {
					if (error.response.data.status === 'Failed') {
						setShowEmailError(true);
						setEmailError(error.response.data.message);
						e.target.value = null;
					}
				});
		}
	};

	const submitForm = () => {
		const Data = UserData;
		axios
			.post('https://registrationapi.ivyarc.com/v1/users/sign-up', Data)
			.then((res) => {
				swal({
					title: 'Registered!',
					text: 'Registration successful check your mail for instructions',
					icon: 'success',
					button: true,
				}).then(() => {
					history.push('/');
				});
			})
			.catch((err) => {
				swal({
					text: `Please email us at: ${ContactIvyarc}`,
					icon: 'error',
					title: 'Registration failed!',
				}).then(() => {
					history.push('/RegistrationForm');
				});
			});
	};

	const onDocumentSubmit = () => async (e) => {
		try {
			const file = e.target.files[0];
			const hasType = docType.includes(file.type);
			if (!hasType) {
				setDocFile(true);
				docFile = false;
				const div = document.getElementsByClassName('errorDocMsg');
				while (div.firstChild) {
					setShowDocUploadError(false);
				}
				setShowDocUploadMsg(false);
				setDocUploadError('Please select a file to upload');
				setShowDocUploadError(true);
				return null;
			}
			const fromData = new FormData();

			fromData.append('document', file);
			const config = {
				headers: {
					'content-type': 'multipart/form-data',
				},
			};
			const url = 'https://registrationapi.ivyarc.com/v1/media/upload/';
			const res = await axios.post(url, fromData, config);
			const cv = res.data.data.document;
			setCV(cv);
			setShowDocUploadMsg(true);
			setShowDocUploadError(false);
			setValues((values) => {
				return {...values, cv: cv};
			});
			setDocFile(true);
			docFile = true;
			runProcess();
		} catch (error) {
			setDocFile(false);
			docFile = false;
			setShowDocUploadMsg(false);
			setDocUploadError('An error occurred. Please try again');
			setShowDocUploadError(true);
		}
	};

	const TechStackList = [
		{label: 'Node.js', value: 'Node.js'},
		{label: 'React.js', value: 'React.js'},
		{label: 'TypeScript', value: 'TypeScript'},
		{label: 'JavaScript', value: 'JavaScript'},
		{label: 'PHP/Laravel', value: 'PHP/Laravel'},
		{label: 'Python', value: 'Python'},
	];
	const imageType = ['image/png', 'image/jpeg', 'image/jpg'];
	const docType = [
		'application/pdf',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	];

	const DATAhandleChange = (e) => {
		const userStack = e.map((x) => x.value);

		setSelectedValue(userStack);
		setValues((values) => {
			return {...values, tech_stacks: userStack};
		});
	};

	const onFormSubmit = (event) => {
		event.preventDefault();
	};
	const updateTerms = () => {
		setAcceptTerms(!acceptTerms);
		setValues((values) => {
			return {...values, accept_terms: !acceptTerms};
		});
	};

	return (
		<div
			className='container mt-5 bg-light text-dark'
			style={{
				boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.2)',
				marginTop: '30px',
			}}>
			<div
				id='registrationForm'
				style={{
					padding: '25px',
				}}>
				<div className='form-header pb-3'>
					<h3
						className='form-title text-dark text-left form--Title-Name'
						id='registrationFormTitle'>
						<Link to='/'>
							<div>
								<span
									className='iconify RegForm--icon'
									data-icon='ic:baseline-arrow-back'
									data-inline='false'
								/>
							</div>
						</Link>
						<div className='RegForm--title'>Registration Form</div>
					</h3>
					<br />
					<span style={{color: 'orange'}}>
						NOTE: Please ensure to provide the following information
					</span>
				</div>
				{/* eslint-disable-next-line no-restricted-globals */}
				<form method={'POST'} onSubmit={onFormSubmit} autoComplete={'off'}>
					<div className='row'>
						<div className='col-lg-6'>
							<div className='form-group'>
								<label>
									Upload Profile Image (.png/.jpeg/.jpg format) *1mb max
								</label>
								{showImgUploadError && (
									<div style={{color: 'red'}} className={'errorImageMsg'}>
										{imgUploadError}
									</div>
								)}
								{showImgUploadMsg && (
									<div style={{color: 'green'}} className={'errorImageMsg'}>
										{imgUploadMsg}
									</div>
								)}
								<div className='input-group'>
									<span className='input-group-btn'>
										<span className='btn btn-default btn-file'>
											<input
												type='file'
												name='picture'
												onChange={onFileSubmit()}
												accept='image/png, image/jpeg, image/jpg'
												required
											/>
										</span>
									</span>
								</div>
							</div>
							{/*<div onClick={onFileSubmit} className={buttonBack}>{pictureButton}</div>*/}
						</div>
						<div className='col-lg-6'>
							<div className='form-group'>
								<label>Upload CV (.pdf/.doc format) *1mb max</label>
								{showDocUploadError && (
									<div style={{color: 'red'}} className={'errorDocMsg'}>
										{docUploadError}
									</div>
								)}
								{showDocUploadMsg && (
									<div style={{color: 'green'}} className={'successfulDocMsg'}>
										{docUploadMsg}
									</div>
								)}
								<div className='input-group'>
									<span className='input-group-btn'>
										<span className='btn btn-default btn-file'>
											<input
												type='file'
												name='document'
												onChange={onDocumentSubmit()}
												accept='application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
												required
											/>
										</span>
									</span>
								</div>
							</div>
							{/*{showDocUploadingButton && <div onClick={onDocumentSubmit} className={buttonBack2}>{docButton}</div>}*/}
						</div>
						<div className='col-lg-6'>
							<div className='form-group'>
								<label htmlFor='firstName'>First Name</label>
								<input
									type='firstName'
									className='form-control'
									value={first_name}
									onChange={handleChange('first_name')}
									onMouseLeave={enableCheckBox()}
									placeholder='Enter First Name'
									required
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='lastName'>Last Name</label>
								<input
									type='lastName'
									className='form-control'
									value={last_name}
									onChange={handleChange('last_name')}
									onMouseLeave={enableCheckBox()}
									placeholder='Enter Last Name'
									required
								/>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='form-group'>
								<label htmlFor='github'>
									GitHub (Write nill if you don't have a GitHub account)
								</label>
								<input
									type='github'
									className='form-control'
									value={github_account}
									onChange={handleChange('github_account')}
									onMouseLeave={enableCheckBox()}
									placeholder='Enter your GitHub Account'
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='email'>Email</label>
								{showEmailError && (
									<div style={{color: 'red'}}>{emailError}</div>
								)}
								<input
									type='email'
									className='form-control'
									value={email}
									autoComplete={'new-password'}
									onChange={handleChange('email')}
									onMouseLeave={enableCheckBox()}
									placeholder='Enter Email'
									required
								/>
								<small id='emailHelp' className='form-text text-muted'>
									We'll never share your email with anyone else.
								</small>
							</div>
						</div>
						<div className='col-lg-4'>
							<div className='form-group'>
								<label htmlFor='gender'>Gender</label>
								<select
									className='form-control'
									id='gender'
									required
									value={gender}
									onChange={handleChange('gender')}
									onMouseLeave={enableCheckBox()}>
									<option value={''}>Select Gender</option>
									<option>Male</option>
									<option>Female</option>
									<option>Prefer not to say</option>
								</select>
							</div>
							<div className='form-group'>
								<label htmlFor='age'>Age</label>
								<select
									className='form-control'
									id='age'
									value={age}
									onChange={handleChange('age')}
									onMouseLeave={enableCheckBox()}
									required>
									<option value={''}>Select Age</option>
									<option>Below 18</option>
									<option>18-25</option>
									<option>26-30</option>
									<option>30 & above</option>
								</select>
							</div>
							<div className='form-group'>
								<label htmlFor='programmingExperience'>
									Programming Experience
								</label>
								<select
									className='form-control'
									id='programming_experience'
									value={programming_experience}
									onChange={handleChange('programming_experience')}
									onMouseLeave={enableCheckBox()}
									required>
									<option value={''}>Select Experience</option>
									<option>No Experience</option>
									<option>Beginner</option>
									<option>Intermediate</option>
									<option>Advanced</option>
									<option>Professional</option>
								</select>
							</div>
						</div>
						<div className='col-lg-4'>
							<div className='form-group'>
								<label htmlFor='selectCourse'>Select Course</label>
								<select
									className='form-control'
									id='course'
									value={course}
									onChange={handleChange('course')}
									onMouseLeave={enableCheckBox()}
									required>
									<option value={''}>Select Course</option>
									<option>Software Engineering</option>
									<option disabled>Product Management</option>
									<option disabled>UI/UX</option>
								</select>
							</div>
							<div className='form-group'>
								<label htmlFor='techStack'>Tech Stacks</label>
								<Select
									onChange={DATAhandleChange} // assign onChange function
									className='dropdown'
									placeholder='Select Option'
									value={TechStackList.filter((obj) =>
										selectedValue.includes(obj.value)
									)} // set selected values
									options={TechStackList} // set list of the data
									isMulti
									isClearable
									required={true}
									onBlur={enableCheckBox()}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='commitmentLevel'>
									Commitment Level to the Internship
								</label>
								<select
									className='form-control'
									id='commitmentLevel'
									value={level_of_commitment}
									onChange={handleChange('level_of_commitment')}
									onMouseLeave={enableCheckBox()}
									required>
									<option value={''}>Select Commitment Level</option>
									<option>50%</option>
									<option>70%</option>
									<option>90%</option>
									<option>100%</option>
								</select>
							</div>
						</div>
						<div className='col-lg-4'>
							<div className='form-group'>
								<label htmlFor='referral'>How did you hear about us?</label>
								<select
									className='form-control'
									id='referral'
									value={how_did_you_hear_about_us}
									onChange={handleChange('how_did_you_hear_about_us')}
									onMouseLeave={enableCheckBox()}
									required>
									<option value={''}>Please Choose</option>
									<option>Friends</option>
									<option>Website</option>
									<option>Google</option>
									<option>Whatsapp Status</option>
									<option>LinkedIn</option>
									<option>Instagram</option>
									<option>Facebook</option>
									<option>Other Social Media</option>
									<option>Others</option>
								</select>
							</div>
							<div className='form-group'>
								<label htmlFor='password'>Password</label>
								<input
									type='password'
									className='form-control'
									value={password}
									onChange={handleChange('password')}
									onMouseLeave={enableCheckBox()}
									placeholder='Enter Password'
									required
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='password'>Phone Number</label>
								<input
									type='phone number'
									className='form-control'
									value={phone_number}
									onChange={handleChange('phone_number')}
									onMouseLeave={enableCheckBox()}
									placeholder='Phone number'
									required
								/>
							</div>
						</div>
						<div className='col-6'>
							<div className='form-group'>
								<label htmlFor='textarea'>
									What are your expectations after training?
								</label>
								<textarea
									className='form-control'
									id='textarea'
									rows='3'
									value={expectations_after_training}
									onMouseLeave={enableCheckBox()}
									onChange={handleChange('expectations_after_training')}
									required
								/>
							</div>
						</div>
						<div className='col-6'>
							<div className='form-group'>
								<label htmlFor='paymentScheme'>
									Preferred payment option
								</label>
								<select
									className='form-control'
									id='referral'
									value={payment_scheme}
									onChange={handleChange('payment_scheme')}
									onMouseLeave={enableCheckBox()}
									required>
									<option value={''}>Please Choose</option>
									<option>Full payment</option>
									<option>Installment </option>
								</select>
							</div>
						</div>
						<div className='col-12 mb-5'>
							<div className='form-group form-check'>
								<input
									type='checkbox'
									onClick={updateTerms}
									className='form-check-input'
									id='confirmationCheck'
									disabled={disableCheckBox}
									required
								/>
								<label className='form-check-label' htmlFor='confirmationCheck'>
									I agree to receive occasional communication from ARC. <br />
									You can unsubscribe from these communications at ay time.
									<br />
									For more information on how to unsubscribe, our privacy
									practice and how we are committed to protecting and respecting
									your privacy, please review our Privacy Policy.
									<br />
									By clicking submit, you consent to allow ARC to store and
									process the personal information submitted to us to provide
									you with the service requested.
								</label>
							</div>
						</div>
						{
							acceptTerms ? <button type='submit' className='submit2-disable' onClick={submitForm}>Submit</button> :
							    <button type="submit" className="submit2-disable" >Submit</button>
							// acceptTerms ? (
							// 	<PaymentSys
							// 		type='submit'
							// 		UserData={UserData}
							// 		submitForm={submitForm}
							// 	/>
							// ) : (
							// 	<button type='submit' className='submit2-disable'>
							// 		Submit
							// 	</button>
							// )
						}
					</div>
				</form>
			</div>
		</div>
	);
}

export default RegistrationForm;
