import React, { Component }  from 'react';
import Modal from "../../components/Modal"

const reservation = () => {
  return (
    <div>
      <Modal />
    </div>
  )
}

export default reservation;
