import React, { Component }  from 'react';
import OpenDayModal from "../../components/OpenDayModal"

const openday = () => {
  return (
    <div>
      <OpenDayModal />
    </div>
  )
}

export default openday;
