import React, { useState } from "react"
import { PaystackButton } from 'react-paystack';
import { useHistory } from "react-router-dom"
import axios from "axios";
import swal from "sweetalert";
const { v4: uuidv4 } = require('uuid');

// function PaymentSys({ UserData}) {
//     const ContactIvyarc = "info@ivyarc.com"
//     const history = useHistory()
//     // eslint-disable-next-line react-hooks/rules-of-hooks
//     const [signUpSuccess, SetSignUpSuccess] = useState(false)
//     const config = {
//         reference: (new Date()).getTime(),
//         email: UserData.email,
//         amount: UserData.payment_scheme === 'Full payment'? 4500000 : 2250000,
//         publicKey: UserData.publicKey,
//     };

//     // you can call this function anything
//     const handlePaystackSuccessAction = (reference) => {
//         UserData.transaction_id = reference.reference
//         const Data = UserData;

//         axios.post('https://registrationapi.ivyarc.com/v1/users/sign-up', Data)
//             .then(res => {
//                 SetSignUpSuccess(true)
//                 swal({
//                     title: "Registered!",
//                     text: "Registration successful check your mail for instructions",
//                     icon: 'success',
//                     button: true
//                 }).then(()=> {
//                     history.push('/')
//                 })
//             })
//             .catch(err => {
//                 SetSignUpSuccess(false)
//                 swal({
//                     text: `Please email us at: ${ContactIvyarc}`,
//                     icon: 'error',
//                     title: "Registration failed!",
//                 }).then(()=> {
//                     history.push('/RegistrationForm')
//                 })
//             }
//             )


//         // Implementation for whatever you want to do with reference and after success call.
//         // submitForm()
//     };

//     // you can call this function anything
//     const handlePaystackCloseAction = () => {
//         // implementation for  whatever you want to do when the Paystack dialog closed.
//         history.push('/RegistrationForm')
//     }

//     const componentProps = {
//         ...config,
//         text: signUpSuccess === true ? `Record saved😎` : "Pay Now",
//         onSuccess: (reference) => handlePaystackSuccessAction(reference),
//         onClose: () => handlePaystackCloseAction(),
//     };

//     return (
//             <>
//                 <PaystackButton {...componentProps} disabled ={true} className="but1" />
//             </>
//         )
// }

function PaymentSys({ UserData}) {
    const history = useHistory()
    UserData.transaction_id = uuidv4()
    const Data = UserData;
    const ContactIvyarc = "info@ivyarc.com"

    axios.post('https://registrationapi.ivyarc.com/v1/users/sign-up', Data)
        .then(res => {
            
            console.log("RES", res.data)
            swal({
                title: "Registered!",
                text: "Registration successful check your mail for instructions",
                icon: 'success',
                button: true
            }).then(()=> {
                history.push('/')
            })
        })
        .catch(err => {
            swal({
                text: `Please email us at: ${ContactIvyarc}`,
                icon: 'error',
                title: "Registration failed!",
            }).then(()=> {
                history.push('/RegistrationForm')
            })
        }
        )
}

export default PaymentSys;